import React, {useContext, createContext, useState, useEffect} from 'react';
import SimpleAssistantAPI from "../../../../common/handlers/api-handlers/SimpleAssistantAPI";
import {useError} from "../../../../common/errors/ErrorProvide";
import PersonAPI from "../../../../common/handlers/api-handlers/PersonAPI";

const PersonContext = createContext();

export const usePersonContext = () => useContext(PersonContext);

export const PersonProvider = ({children}) => {
    const [personForm, setPersonForm] = useState(null);
    const [loadingPersonForm, setLoadingPersonForm] = useState(false);

    const [voluntaryPersonForm, setVoluntaryPersonForm] = useState(null);
    const [loadingVoluntaryPersonForm, setLoadingVoluntaryPersonForm] = useState(false);

    const [persons, setPersons] = useState([]);
    const [personsLoading, setIsLoading] = useState(false);
    const [activePerson, setActivePerson] = useState(null);

    const API = new SimpleAssistantAPI();
    const personApi = new PersonAPI();

    const {showError} = useError();
    const [personFormData, setPersonFormData] = useState(null);
    const [voluntaryPersonFormData, setVoluntaryPersonFormData] = useState(null);
    const [voluntaryLoading, setVoluntaryLoading] = useState(false);

    const [reloadActivePersonContent, setReloadActivePersonContent] = useState(false);

    useEffect(() => {
        _getPersonsFromAPI();
    }, []);

    const getFormData = () => {
        return {
            'fields': personForm,
            'provided_data_set': personFormData
        };
    }

    const _apiResponseError = (response) => {
        showError(response);
    }

    const _sortApiResponsePersons = (response, reload_active_person = true) => {
        if (response === null || response === undefined) return [];
        if (response.persons === null || response.persons === undefined) return [];
        if (response.active_person !== null && reload_active_person) {
            setActivePerson(response.active_person);
        }
        const data = response.persons;
        if (data === null || data === undefined) return [];
        let new_persons = [];
        Object.entries(data).forEach(([id, dict]) => {
            new_persons.push(dict);
        });
        return new_persons;
    }

    const _getPersonsFromAPI = async () => {
        // bla
        setIsLoading(true);
        const [success, response] = await API.getPersons();
        if (success) {
            const data = response;
            setPersons(_sortApiResponsePersons(data));
        } else {
            _apiResponseError(response);
        }
        setIsLoading(false);
    }

    const _addPersonToAPI = async (data, reload_active_person) => {
        // bla
        console.log(data, 'addPersonToAPI');
        setIsLoading(true);
        const [success, response] = await API.addPerson(data);
        if (success) {
            setPersons(_sortApiResponsePersons(response, reload_active_person));
            let retData = null;
            if (data["last-added"] !== null || data["last-added"] !== undefined) {
                retData = response["last-added"];
            }
            setIsLoading(false);
            return retData;
        }else {
            _apiResponseError(response);
            setIsLoading(false);
            return null;
        }
        setIsLoading(false);
    }

    const _removePersonFromAPI = async (personId) => {
        // bla
        setIsLoading(true);
        const [success, response] = await API.removePerson(personId);
        if (success) {
            const data = response;
            setPersons(_sortApiResponsePersons(data));
        } else {
            _apiResponseError(response);
        }
        setIsLoading(false);
    }

    const _updatePersonInAPI = async (data, reloadContent = false) => {
        // TODO: Create this function for updating a person's data
        const [success, response] = await API.updatePerson(data);
        if (success) {
            const data = response.data;
            console.log(data);
            if (reloadContent) {
                setReloadActivePersonContent(true);
            }
            return data;
        } else {
            try {
                const [data_success, data_response] = response;
                if (data_success) {
                    return data_response.context;
                } else {
                    console.log('ERROR', data_response);
                    _apiResponseError(data_response);
                    return null;
                }
            } catch (e) {
                console.log('ERROR', e);
                _apiResponseError(response);
                return null;
            }
            return null;
        }
    }

    const _setActivePersonInAPI = async ({activePersonId}) => {

        setIsLoading(true);
        const [success, response] = await API.updateActivePerson(activePersonId);
        if (success) {
            const data = response;
            if (data["active_person"] !== null || data["active_person"] !== undefined) {
                setActivePerson(data["active_person"]);
            } else {
                _apiResponseError("ERROR")
            }
        } else {
            _apiResponseError(response);
        }
        setIsLoading(false);
    }

    const _getActivePersonFromAPI = async () => {
        // bla
        setIsLoading(true);
        const [success, response] = await API.getActivePerson();
        if (success) {
            const data = response;
            if (data["active_person"] !== null || data["active_person"] !== undefined) {
                setActivePerson(data["active_person"]);
            } else {
                _apiResponseError("ERROR")
            }

        } else {
            _apiResponseError(response);
        }
        setIsLoading(false);
    }

    const _clearActivePersonInAPIAndLocalStorage = () => {
        // TODO: Check if this is needed
        setActivePerson(null);
        sessionStorage.removeItem('sBactiveP');
    }

    const getPersons = () => {
        return persons;
    }

    const addPerson = async (data, reload_active_person=true) => {
        setIsLoading(true);

        if (!reload_active_person) {
            data['actions'] = {
                'type': 'no-active-person'
            }
        }

        // setPersons([...persons, newPerson]);
        let lastAdded = await _addPersonToAPI(data, reload_active_person);
        return lastAdded;
    }

    const removePerson = (id) => {
        let newPersons = persons.filter((person) => String(person.id) !== String(id));
        _removePersonFromAPI(id);
        setPersons(newPersons);
    }

    const selectActivePerson = (id) => {
        _setActivePersonInAPI({activePersonId: id});
    }

    const getActivePerson = () => {
        return _getActivePersonFromAPI();
    }

    const getPersonAddForm = async () => {
        setLoadingPersonForm(true);
        const [success, response] = await personApi.getAddPersonForm();
        if (success) {
            setPersonForm(response.data.fields);
            setPersonFormData(response.data.provided_data_set);
            setVoluntaryPersonForm(response.data.voluntary.fields);
            setVoluntaryPersonFormData(response.data.voluntary.provided_data_set);
        } else {
            _apiResponseError(response);
        }
        setLoadingPersonForm(false);
    }

    useEffect(() => {
        getPersonAddForm();
    }, []);

    useEffect(() => {
        if (reloadActivePersonContent) {
            getActivePerson();
            setReloadActivePersonContent(false);
        }
    }, [reloadActivePersonContent]);

    const updatePerson = async (data, reloadContent, voluntaryLoading=true) => {
        if (voluntaryLoading) {
            setVoluntaryLoading(true);
        }
        const response_data = await _updatePersonInAPI(data, reloadContent);
        setVoluntaryLoading(false);
        return response_data;
    }

    return (
        <PersonContext.Provider
            value={{
                persons,
                getPersons,
                _getPersonsFromAPI,
                addPerson,
                removePerson,
                personsLoading,
                selectActivePerson,
                getActivePerson,
                activePerson,
                getFormData,
                getPersonAddForm,
                personFormData,
                personForm,
                voluntaryPersonForm,
                voluntaryPersonFormData,
                voluntaryLoading,
                updatePerson,
                reloadActivePersonContent
        }}>
            {children}
        </PersonContext.Provider>
    );
}