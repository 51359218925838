import React, {useEffect, useState} from 'react';
import {Box, Card, CircularProgress, Typography} from "@mui/material";
import PaymentAPI from "../../../../../common/handlers/api-handlers/PaymentAPI";
import PayPalCheckoutForm from "./paymentTypes/paypal/PayPalCheckoutForm";
import StripeCheckout from "./paymentTypes/stripe/StripeCheckout";

function PaymentSelection({t, paymentMethods, paymentPossible=false, address, handleStepCompleted}) {
    const [paymentType, setPaymentType] = useState(null);
    const API = new PaymentAPI();

    const [fetchedPaymentType, setFetchedPaymentType] = useState(false);

    const fetchPaymentType = async () => {
        await API.getPaymentType().then(async ([success, response]) => {
            if (!success) {
                return;
            }
            setFetchedPaymentType(true);


            await new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 200);
            })

            setPaymentType(response.payment_type);
        });
    }

    useEffect(() => {

        fetchPaymentType();

    }, []);


    if (!fetchedPaymentType || !paymentType) {
        return (
            <Box>
                <CircularProgress />
            </Box>
        );
    } else {
        if (paymentType === "stripe") {
            return (
                <StripeCheckout address={address} />
            );
        } else if (paymentType === "paypal") {
            return (
                <PayPalCheckoutForm handleStepCompleted={handleStepCompleted}/>
            );
        } else {
            return (
                <Card variant={"outlined"}>
                    <Typography variant={"h6"}>{t("cart.payment.title")}</Typography>
                    <Typography variant={"body1"}>{t("cart.payment.not-possible")}</Typography>
                </Card>
            );
        }
    }

    return (
        <Card variant={"outlined"}>
            <Typography variant={"h6"}>{t("cart.payment.title")}</Typography>
            {
                paymentPossible ? (
                    <Typography variant={"body1"}>{t("cart.payment.possible")}</Typography>
                ) : (
                    <Typography variant={"body1"}>{t("cart.payment.not-possible")}</Typography>
                )
            }
        </Card>
    );
}

export default PaymentSelection;