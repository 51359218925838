import React, {useEffect} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ProductComponent from "./ProductComponent";
import {useTranslation} from "react-i18next";
import {useMobileViewContext} from "../../globals/MobileViewContext";
import {CalendarSelectionProvider} from "../../globals/CalendarContext";
import {useCartContext} from "../../../../../common/providers/CartContext";


const initContentMargin = '10px';

function AccordionComponent({itemData}) {

    const {i18n, t} = useTranslation();

    let currentLanguage = i18n.language;
    let fallbackLanguage = i18n.options.fallbackLng[0];

    const [contentMargin, setContentMargin] = React.useState(initContentMargin)
    const {viewType} = useMobileViewContext();

    const [expanded, setExpanded] = React.useState(false);

    const {cartUpdated, setCartUpdated} = useCartContext();

    useEffect(() => {
        let newContentMargin = initContentMargin;

        if (viewType === 'mobile' || viewType === 'tablet') {
            newContentMargin = '5px';
        }

        setContentMargin(newContentMargin);
    }, [viewType]);

    useEffect(() => {
        if (cartUpdated) {
            setExpanded(false);
            setCartUpdated(false);
        }
    }, [cartUpdated]);

    return (
        <Accordion
            key={itemData.id}
            sx={{ width: '100%' }}
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
        >
            <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                    transition: '0.3s',
                    backgroundColor: '#f9f9f9',
                    '&.Mui-expanded': {
                        borderBottom: '1px solid #f0f0f0',
                    },
                    ':hover': {
                        cursor: 'pointer',
                        backgroundColor: '#f0f0f0'
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: '3px'
                    }}
                >
                    <Box>{
                        (itemData.name[currentLanguage] !== undefined) ? itemData.name[currentLanguage] : itemData.name[fallbackLanguage]
                    }</Box>
                    {
                        (itemData["amount_inner"] !== undefined)
                            ? <Box>({itemData["amount_inner"]})</Box>
                            : <></>
                    }
                </Box>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    paddingX: contentMargin
                }}
            >
                <p>{
                    (itemData.description[currentLanguage] !== undefined) ? itemData.description[currentLanguage] : itemData.description[fallbackLanguage]
                }</p>
                {
                    (itemData.display.img !== undefined)
                        ? (
                            <img
                                src={itemData.display.img.src}
                                alt={itemData.display.img.alt}
                                style={{
                                    maxWidth: "200px",
                                    maxHeight: "200px",
                                    objectFit: itemData.display.img["object-fit"],
                                }}
                            />
                        )
                        : <></>
                }
                {
                    (itemData.children !== undefined)
                        ? (
                            <Box>
                                {
                                    itemData.children.map((item, index) => {
                                        return (
                                            <AccordionComponent itemData={item} key={item.id} />
                                        )
                                    })
                                }
                            </Box>
                        )

                        : <></>
                }

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                        my: 3
                    }}
                >

                </Box>
                {
                    (itemData.products.bookable !== undefined)
                        ? (
                            <Box>
                                {
                                    itemData.products.bookable.map((item, index) => {
                                        return (
                                            <CalendarSelectionProvider key={item.id + 'child_from' + itemData.id}>
                                                <ProductComponent  itemData={item} bookable={true} />
                                            </CalendarSelectionProvider>
                                        )
                                    })
                                }
                            </Box>
                        )
                        : <></>
                }
                {
                    (itemData.products.not_bookable !== undefined)
                        ? (
                            <Box>
                                {
                                    itemData.products.not_bookable.map((item, index) => {
                                        return (
                                            <CalendarSelectionProvider key={item.id + 'child_from' + itemData.id}>
                                                <ProductComponent itemData={item} bookable={false} />
                                            </CalendarSelectionProvider>
                                        )
                                    })
                                }
                            </Box>
                        )
                        : <></>
                }
            </AccordionDetails>
        </Accordion>
    );
}

export default AccordionComponent;