import APICall from "../APICall";

class PaymentAPI extends APICall {

    constructor() {
        super();
    }

    async getPaymentType(){
        await super.fetchConfig();

        return await super.makeGetRequest({
            request: {
                apiType: 'payment',
                route: 'getType'
            },
            query: null
        });
    }

    async createPaymentIntent(paymentType){
        await super.fetchConfig();

        if (paymentType === 'stripe') {
            return await super.makePostRequest({
                request: {
                    apiType: 'stripePayment',
                    route: 'createIntent'
                },
                data: {},
                query: null
            });
        } else if (paymentType === 'paypal') {
            return await super.makePostRequest({
                request: {
                    apiType: 'paypalPayment',
                    route: 'createOrder'
                },
                data: {},
                query: null
            })
        } else {
            return {"error": "Payment type not supported", "status": 400};
        }
    }
    
    async paypalInit() {
        await super.fetchConfig();
        
        return await super.makePostRequest({
            request: {
                apiType: 'paypalPayment',
                route: 'init'
            },
            data: {},
            query: null
        });
    }

    async stripeInit() {
        await super.fetchConfig();

        return await super.makePostRequest({
            request: {
                apiType: 'stripePayment',
                route: 'init'
            },
            data: {},
            query: null
        });
    }

    async capturePayPalOrder(orderID) {
        await super.fetchConfig();

        return await super.makePostRequest({
            request: {
                apiType: 'paypalPayment',
                route: 'captureOrder',
            },
            data: {
                order_id: orderID
            },
            query: null
        });
    }
}

export default PaymentAPI;