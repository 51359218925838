import React from 'react';
import {Box, Button, CircularProgress, Step, StepLabel, Stepper, Typography} from "@mui/material";
import CartInventory from "./components/CartInventory";
import AddressField from "./components/AddressField";
import {useTranslation} from "react-i18next";
import {useError} from "../../../../common/errors/ErrorProvide";
import PaymentSelection from "./components/PaymentSelection";
import CartAPI from "../../../../common/handlers/api-handlers/CartAPI";
import ThankYou from "./components/ThankYou";
import RequestCheck from "./components/RequestCheck";
import {MobileViewProvider} from "../../../templates/simpleAssistant/globals/MobileViewContext";

const steps = {
    'default': ['cart.stepper.cart', 'cart.stepper.address', 'cart.stepper.payment', 'cart.stepper.confirmation'],
    'request': ['cart.stepper.cart', 'cart.stepper.address', 'cart.stepper.request', 'cart.stepper.payment', 'cart.stepper.confirmation'],
};

function CartStepper({initActiveStep = 0, initCartType = null, initRequestData = null, directThankYou=false}) {
    const [activeStep, setActiveStep] = React.useState(initActiveStep);
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = React.useState(false);
    const [canUseNext, setCanUseNext] = React.useState(false);
    const [requestData, setRequestData] = React.useState(initRequestData);
    const { showError } = useError();

    const [cartType, setCartType] = React.useState(initCartType);

    const cartAPI = new CartAPI();

    const [address, setAddress] = React.useState({
        'fname': '',
        'sname': '',
        'street': '',
        'house_number': '',
        'plz': '',
        'city': '',
        'country': '',
        'phone01': '',
        'phone02': '',
        'email01': '',
        'email02': '',
        'note': '',
        'country-others': false,
        'country-other': '',
        'newsletter': false,
        'terms': false,
        'privacy': false,
    });

    const handleAddressChange = (e) => {
        if (e.target.type === 'checkbox') {
            setAddress({
                ...address,
                [e.target.name]: e.target.checked,
            });
            sessionStorage.setItem('address', JSON.stringify(address));
            return;
        }

        setAddress({
            ...address,
            [e.target.name]: e.target.value,
        });
        sessionStorage.setItem('address', JSON.stringify(address));
    }

    const handleAPICall = async () => {
        setIsLoading(true);
        try{
            if (activeStep === 0) {
                // call API for storing the cart into the database
                return true;
            } else if (activeStep === 1) {
                if (address.fname === '' || address.sname === '' || address.street === '' || address.housenr === '' || address.plz === '' || address.city === '' || address.country === '' || address.phone01 === '' || address.email01 === '') {
                    showError("Please fill out all fields.");
                    return false;
                }
                // call API for storing the address to the cart order

                const [success, response] = await cartAPI.addressCheckout(address);
                if (!success) {
                    showError(response);
                    return false;
                } else {
                    if (response.request_data !== undefined && response.request_data !== null) {
                        setRequestData(response.request_data);
                    }
                }
                return true;
            } else if (activeStep === 2) {
                // call API for checking the payment
                return true;
            } else {
                return false;
            }
        } finally {
            setIsLoading(false);
        }
        return false;
    }

    const checkIsOnPaymentPage = (nextStep) => {
        console.log(cartType, nextStep);
        if (cartType !== null) {
            console.log('Cart type not null')
            if (cartType === 'default' && nextStep === 2) {
                console.log('is default and next is 2, nextStep:', nextStep)
                setCanUseNext(false);
            } else if (cartType === 'request' && nextStep === 3) {
                console.log('is request and next is 3, nextStep: ', nextStep)
                setCanUseNext(false);
            } else {
                console.log('is any other', cartType, nextStep)
                setCanUseNext(true);
            }
        } else {
            setCanUseNext(true);
        }
    }

    const handleNext = async () => {
        const success = await handleAPICall();
        if (!success) {
            return;
        }
        let nextStep = activeStep + 1;
        setActiveStep(nextStep);
        checkIsOnPaymentPage(nextStep);
    }

    const handleBack = () => {
        let nextStep = activeStep - 1;
        setActiveStep(nextStep);
        checkIsOnPaymentPage(nextStep);
    }

    const handleReset = () => {
        setActiveStep(0);
    };

    React.useEffect(() => {
        const getCartType = async () => {
            if (initCartType === null) {
                const [success, response] = await cartAPI.getCartType();
                if (success) {
                    if (response.data !== undefined && response.data.cart_type !== undefined) {
                        setCartType(response.data.cart_type);
                    } else {
                        showError("Network error. Please try again later.");
                    }
                } else {
                    showError(response);
                }
            }
        }
        getCartType();
        if (sessionStorage.getItem('address') !== null) {
            setAddress(JSON.parse(sessionStorage.getItem('address')));
        }
    }, []);

    React.useEffect(() => {
        if (cartType !== null) {
            checkIsOnPaymentPage(activeStep);
        }
    }, [activeStep, cartType]);

    if (cartType === null) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <CircularProgress />
            </Box>
        )
    }

    return (
        <MobileViewProvider>
            <Box>
                <Stepper sx={{
                    mb: 2,
                }} activeStep={activeStep} alternativeLabel>
                    {
                        steps[cartType].map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};

                            return (
                                <Step key={label + index} {...stepProps}>
                                    <StepLabel {...labelProps}>{t(label)}</StepLabel>
                                </Step>
                            )
                        })
                    }
                </Stepper>
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <CartStepperContentSelector
                        cartType={cartType}
                        activeStep={activeStep}
                        address={address}
                        setActiveStep={setActiveStep}
                        handleAddressChange={handleAddressChange}
                        t={t}
                        requestData={requestData}
                        directThankYou={directThankYou}
                        handleStepCompleted={handleNext}
                    />
                )
                }
                <CartStepperBtnSelector
                    cartType={cartType}
                    activeStep={activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    t={t}
                    canUseNext={canUseNext}
                />
            </Box>
        </MobileViewProvider>
    );
}

function CartStepperContentSelector({cartType, activeStep, setActiveStep, address, handleAddressChange, t, requestData = null, directThankYou=false, handleStepCompleted}) {
    if (cartType === 'default') {
        if (directThankYou) {
            setActiveStep(3);
        }
        return <CartStepperContentDefault activeStep={activeStep} address={address} handleAddressChange={handleAddressChange} t={t} handleStepCompleted={handleStepCompleted} />
    } else if (cartType === 'request') {
        if (directThankYou) {
            setActiveStep(4);
        }
        return <CartStepperContentRequest activeStep={activeStep} address={address} handleAddressChange={handleAddressChange} t={t} requestData={requestData} handleStepCompleted={handleStepCompleted} />
    }
}

function CartStepperContentDefault({activeStep, address, handleAddressChange, t, handleStepCompleted}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: 'auto',
                maxWidth: { xs: '100%', sm: '90%', md: '80%' }, // Adjust based on screen size
                padding: { xs: '16px', sm: '24px', md: '32px' }, // Responsive padding
            }}
        >
            {activeStep === 0 && <CartInventory t={t}/>}
            {activeStep === 1 && <AddressField t={t} address={address} handleAddressChange={handleAddressChange}/>}
            {activeStep === 2 && <PaymentSelection t={t} address={address} handleStepCompleted={handleStepCompleted}/>}
            {activeStep === 3 && <ThankYou />}
        </Box>
    )
}

function CartStepperContentRequest({activeStep, address, handleAddressChange, t, requestData = null, handleStepCompleted}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                maxWidth: { xs: '100%', sm: '90%', md: '80%' }, // Adjust based on screen size
                padding: { xs: '16px', sm: '24px', md: '32px' }, // Responsive padding
                margin: 'auto'
            }}
        >
            {activeStep === 0 && <CartInventory t={t}/>}
            {activeStep === 1 && <AddressField t={t} address={address} handleAddressChange={handleAddressChange}/>}
            {activeStep === 2 && <RequestCheck t={t} requestData={requestData}/> }
            {activeStep === 3 && <PaymentSelection t={t} address={address} handleStepCompleted={handleStepCompleted}/>}
            {activeStep === 4 && <ThankYou />}
        </Box>
    )
}

function CartStepperBtnSelector({cartType, activeStep, handleBack, handleNext, t, canUseNext}) {

    let lastStep = steps[cartType].length - 1;

    return <CartStepperBtnComponent lastStep={lastStep} activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} t={t} canUseNext={canUseNext}/>
}

function CartStepperBtnComponent({cartType, lastStep, activeStep, handleBack, handleNext, t, canUseNext}) {
    console.log('can use next: ', canUseNext)
    return (
        (activeStep !== lastStep) ?
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-between', width: '50%' }}>
                    <Button
                        color="inherit"
                        variant="contained"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        {t('cart.btns.back')}
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />

                    <Button
                        onClick={handleNext}
                        variant="contained"
                        disabled={(canUseNext !== null) ? !canUseNext : false}
                    >
                        {activeStep === steps.length - 1 ? t('cart.btns.finish') : t('cart.btns.next')}
                    </Button>
                </Box>
            </Box>
            : <></>
    )
}

export default CartStepper;