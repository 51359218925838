import React, {useEffect, useState} from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {Box, CircularProgress} from "@mui/material";
import PaymentAPI from "../../../../../../../common/handlers/api-handlers/PaymentAPI";

// Renders errors or successfull transactions on the screen.
function Message({ content }) {
    return <p>{content}</p>;
}

function PayPalCheckoutForm({ handleStepCompleted }) {

    const paymentApi = new PaymentAPI();
    const [options, setOptions] = useState(null);

    const [message, setMessage] = useState("");

    const initPayPal = async () => {
        const [success, response] = await paymentApi.paypalInit();
        if (success) {
            setOptions(response.data);
        } else {
            setMessage(`Could not initiate PayPal Checkout...${response}`);
        }
    }

    useEffect(() => {
        initPayPal();
    }, []);

    if (!options) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <CircularProgress />
            </Box>
        )
    }

    return (
        <div style={{ width: '100%', maxWidth: '700px', marginBottom: 0 }}>
            <PayPalScriptProvider options={options}>
                <PayPalButtons
                    style={{
                        shape: "rect",
                        layout: "vertical",
                        color: "gold",
                        label: "paypal"
                    }}
                    onCancel={() => {
                        setMessage("The payment was cancelled!");
                    }}
                    createOrder={async () => {
                        try {

                            const [success, response] = await paymentApi.createPaymentIntent('paypal')
                            if (!success) {
                                throw new Error(response);
                            }
                            console.log(response, "RESPONSE");
                            const orderData = await response.data;
                            console.log(orderData, "ORDER DATA");

                            if (orderData.id) {
                                return orderData.id;
                            } else {
                                const errorDetail = orderData?.details?.[0];
                                const errorMessage = errorDetail
                                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                                    : JSON.stringify(orderData);

                                throw new Error(errorMessage);
                            }
                        } catch (error) {
                            console.error(error, "CALLED ERROR");
                            setMessage(`Could not initiate PayPal Checkout...${error}`);
                        }
                    }}
                    onApprove={async (data, actions) => {
                        try {

                            const [success, response] = await paymentApi.capturePayPalOrder(data.orderID)
                            if (!success) {
                                throw new Error(response);
                            }
                            const orderData = await response.data;
                            // Three cases to handle:
                            //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                            //   (2) Other non-recoverable errors -> Show a failure message
                            //   (3) Successful transaction -> Show confirmation or thank you message

                            const errorDetail = orderData?.details?.[0];

                            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                                return actions.restart();
                            } else if (errorDetail) {
                                // (2) Other non-recoverable errors -> Show a failure message
                                throw new Error(
                                    `${errorDetail.description} (${orderData.debug_id})`,
                                );
                            } else {
                                // (3) Successful transaction -> Show confirmation or thank you message
                                // Or go to another URL:  actions.redirect('thank_you.html');
                                const transaction =
                                    orderData.purchase_units[0].payments.captures[0];
                                setMessage(
                                    `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`,
                                );
                                console.log(
                                    "Capture result",
                                    orderData,
                                    JSON.stringify(orderData, null, 2),
                                );

                                handleStepCompleted();
                            }
                        } catch (error) {
                            console.error(error);
                            setMessage(
                                `Sorry, your transaction could not be processed...${error}`,
                            );
                        }
                    }}
                    onError={(error) => {
                        setMessage(`An error occurred...${error}`);
                    }}
                />
            </PayPalScriptProvider>
            <Message content={message} />
        </div>
    )
}

export default PayPalCheckoutForm;