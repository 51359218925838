import React from 'react';
import {
    Box,
    Card,
    Checkbox,
    FormControl, FormControlLabel, FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import countries from '../../../../../config/countries.json';

function AddressField({ isMobile, address, handleAddressChange, t }) {

    // const [address, setAddress] = React.useState();

    // const handleAddressChange = (e) => {
    //     setAddress({
    //         ...address,
    //         [e.target.name]: e.target.value,
    //     });
    // }

    return (
        <Card variant={"outlined"} sx={{p: 2, maxWidth: '700px'}}>
            <Typography variant={"h6"}>Adresse</Typography>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TextField required fullWidth id="outlined-basic" label={t("cart.fields.fname")} variant="outlined" type="text" name="fname" value={address.fname} onChange={handleAddressChange} placeholder={t("cart.fields.fname")} />
                </Grid>
                <Grid item xs={6}>
                    <TextField required fullWidth id="outlined-basic" label={t("cart.fields.sname")} variant="outlined" type="text" name="sname" value={address.sname} onChange={handleAddressChange} placeholder={t("cart.fields.sname")} />
                </Grid>
                <Grid item xs={6}>
                    <TextField required fullWidth id="outlined-basic" label={t("cart.fields.phone01")} variant="outlined" type="tel" name="phone01" value={address.phone01} onChange={handleAddressChange} placeholder={t("cart.fields.phone01")} />
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth id="outlined-basic" label={t("cart.fields.phone02")} variant="outlined" type="tel" name="phone02" value={address.phone02} onChange={handleAddressChange} placeholder={t("cart.fields.phone02")} />
                </Grid>
                <Grid item xs={12}>
                    <TextField required fullWidth id="outlined-basic" label={t("cart.fields.email01")} variant="outlined" type="email" name="email01" value={address.email01} onChange={handleAddressChange} placeholder={t("cart.fields.email01")} />
                </Grid>
                <Grid item xs={8}>
                    <TextField required fullWidth id="outlined-basic" label={t("cart.fields.street")} variant="outlined" type="text" name="street" value={address.street} onChange={handleAddressChange} placeholder={t("cart.fields.street")} />
                </Grid>
                <Grid item xs={4}>
                    <TextField required fullWidth id="outlined-basic" label={t("cart.fields.housenr")} variant="outlined" type="text" name="house_number" value={address.house_number} onChange={handleAddressChange} placeholder={t("cart.fields.housenr")} />
                </Grid>
                <Grid item xs={4}>
                    <TextField required fullWidth id="outlined-basic" label={t("cart.fields.zip")} variant="outlined" type="text" name="plz" value={address.plz} onChange={handleAddressChange} placeholder={t("cart.fields.zip")} />
                </Grid>
                <Grid item xs={8}>
                    <TextField required fullWidth id="outlined-basic" label={t("cart.fields.city")} variant="outlined" type="text" name="city" value={address.city} onChange={handleAddressChange} placeholder={t("cart.fields.city")} />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel id={"cart-country-selection"}required>{t("cart.fields.country")}</InputLabel>
                    <Select required fullWidth id="outlined-basic" labelId={"cart-country-selection"} variant="outlined" type="text" name="country" value={address.country} onChange={handleAddressChange} placeholder={t("cart.fields.country")}>
                        <MenuItem value={""}><em>{t("cart.fields.country")}</em></MenuItem>
                        {
                            countries.map((country, index) => {
                                return (
                                    <MenuItem key={index} value={country.code}>{country.name}</MenuItem>
                                );
                            })
                        }
                    </Select>
                </Grid>
                {
                    (address.country === "00") ? (
                        <Grid item xs={12}>
                            <TextField required fullWidth id="outlined-basic" label={t("cart.fields.country-other")} variant="outlined" type="text" name="country-other" value={address["country-other"]} onChange={handleAddressChange} placeholder={t("cart.fields.country-other")} />
                        </Grid>
                    ) : (
                        <></>
                    )
                }
                <Grid item xs={12}>
                    <TextField fullWidth rows={3} multiline id="outlined-basic" label={t("cart.fields.notes")} variant="outlined" type="text" name="note" value={address.note} onChange={handleAddressChange} placeholder={t("cart.fields.notes")} />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox />} label="Newsletter" name="newsletter" onChange={handleAddressChange} checked={address.newsletter} />
                    <FormHelperText>Ich möchte auf dem neuesten Stand bleiben, wenn es von unserer Skischule Angebote oder Informationen gibt.</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox />} label="AGB" name="terms" onChange={handleAddressChange} checked={address.terms} required />
                    <FormHelperText>Hiermit akzeptiere ich die <a href={''}>AGB</a> einer Buchung bei --Skischulname--</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox />} label="Datenschutz" name="privacy" onChange={handleAddressChange} checked={address.privacy} required />
                    <FormHelperText>Hiermit akzeptiere ich die <a href={''}>DSGVO</a> einer Buchung bei --Skischulname--</FormHelperText>
                </Grid>
            </Grid>
            <small dangerouslySetInnerHTML={{
                __html: t("cart.fields.required")
            }}></small>
        </Card>
    );
}

export default AddressField;