import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, Typography} from "@mui/material";
import {usePersonContext} from "../globals/PersonContext";
import AccordionComponent from "./accordion/AccordionComponent";
import {useTranslation} from "react-i18next";
import {useProductContext} from "../globals/ProductContext";
import CategoryAccordion from "./productSelectAccordion/CategoryAccordion";


function SelectionComponent({template}) {

    const {getActivePerson, activePerson, persons, reloadActivePersonContent} = usePersonContext();
    const {productsLoading, getProducts, products, setProducts} = useProductContext();
    const [isLoading, setIsLoading] = useState(false);
    const [useEmpty, setUseEmpty] = useState(false);
    const {t} = useTranslation();

    const [viewAsProductSelect, setViewAsProductSelect] = useState(false);

    useEffect(() => {
        console.log(reloadActivePersonContent)
        getActivePerson();

        if (activePerson === null || activePerson === undefined || activePerson === "") {
            setProducts([]);
        } else {
            if (persons.length > 0) {
                getProducts(activePerson)
            }
        }

    }, [activePerson, reloadActivePersonContent]);

    useEffect(() => {
        if (productsLoading) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [productsLoading]);

    useEffect(() => {
        if (!persons || persons.length === 0) {
            setUseEmpty(true);
        } else {
            setUseEmpty(false);
        }
    }, [persons]);

    useEffect(() => {
        if (template !== null) {
            setViewAsProductSelect(template.productViewer.as_select);
        }
    }, [template]);

    if (isLoading || products === null || productsLoading || template === null) {
        return (
            <Box
                id={'simple-assistant-selection'}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    my: 3
                }}
            >
                <CircularProgress />
            </Box>
        )
    }

    if (useEmpty) {
        return (
            <Box
                id={'simple-assistant-selection'}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    my: 3
                }}
            >
                <Typography variant={'h6'}>{
                    t('simple-assistant.errors.noPersons')
                }</Typography>
            </Box>
        )
    }

    if (viewAsProductSelect) {
        return (
            <Box
                id={'simple-assistant-selection'}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    my: 3
                }}
            >
                {
                    (products && products.categories) ? (
                        products.categories.map((item, index) => {
                            return (<CategoryAccordion itemData={item} key={item.id} />)
                        })
                    ) :
                        (
                            <Typography variant={'h6'}>{
                                t('simple-assistant.errors.noProducts')
                            }</Typography>
                        )
                }
            </Box>
        );
    } else {
        return (
            <Box
                id={'simple-assistant-selection'}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    my: 3
                }}
            >
                {
                    products.categories.map((item, index) => {
                        return (<AccordionComponent itemData={item} key={item.id} />)
                    })
                }
            </Box>
        );
    }
}

export default SelectionComponent;