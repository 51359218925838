import React from 'react';
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import SliderComponent from "../../../../templates/simpleAssistant/footer/SliderComponent";
import CartAPI from "../../../../../common/handlers/api-handlers/CartAPI";
import {useCartContext} from "../../../../../common/providers/CartContext";

function ThankYou() {

    const { cleanUpSession } = useCartContext();

    const {t} = useTranslation();

    React.useEffect(() => {
        cleanUpSession();
    }, [])

    return (
        <>
            <Typography variant={"h1"} sx={{my: 5 }}>{t('cart.thank-you')}</Typography>
            <SliderComponent />
        </>
    );
}

export default ThankYou;