import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button, CircularProgress,
    Divider,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {sxCell, sxRow, sxTable} from "./RequestSx";
import {
    Verified,
    PauseCircleOutline,
    EditNote,
    MarkEmailRead,
    Checklist,
    VerifiedUser, ScheduleSend, AutoMode, ErrorOutline, NewReleases, Rule, Send, CheckCircleOutline
} from "@mui/icons-material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {useMobileViewContext} from "../../../../../templates/simpleAssistant/globals/MobileViewContext";
import {LoadPageStyles} from "../../../../../templates/TemplateLoader";
import CartAPI from "../../../../../../common/handlers/api-handlers/CartAPI";

const requestStatus = {
    'created': {
        icon: ScheduleSend,
        color: 'grey',
    },
    'sent': {
        icon: MarkEmailRead,
        color: '#4163c4',
    },
    'processing': {
        icon: PauseCircleOutline,
        color: 'orange',
    },
    'accepted_by_customer': {
        icon: VerifiedUser,
        color: 'green',
    },
    'accepted_by_admin': {
        icon: Verified,
        color: 'green',
    },
    'rejected_by_customer': {
        icon: Rule,
        color: '#d55555',
    },
    'rejected_by_admin': {
        icon: NewReleases,
        color: '#d55555',
    },
    'edited': {
        icon: EditNote,
        color: '#4163c4',
    },
    'done': {
        icon: Checklist,
        color: 'green',
    },
    'automatic_done': {
        icon: AutoMode,
        color: 'green',
    }
}

function RequestAddressCell({t, title, value}) {
    return (
        <Box
            sx={{...sxCell}}
        >
            <Typography variant={"body1"}>{t(title)}{
                (value === null) ? (
                    ''
                ) : (
                    ':'
                )
            } </Typography>
            <Typography variant={"body1"}>{value}</Typography>
        </Box>
    )
}

function RequestAddressComponent({t, billing}) {

    const [rowSx, setRowSx] = React.useState(sxRow);
    const { viewType } = useMobileViewContext();

    const changeRowSx = () => {
        let newSxRow = {...sxRow};
        if (viewType === 'mobile') {
            newSxRow.flexDirection = 'column';
            newSxRow.gap = '5px';
        }
        setRowSx(newSxRow);
    }
    React.useEffect(() => {
        changeRowSx();
    }, [viewType])

    React.useEffect(() => {
        changeRowSx();
    }, [])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                pt: 3,
            }}
        >
            {
                (billing !== null && billing !== undefined) ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{...sxTable}}
                        >
                            <Box
                                sx={rowSx}
                            >
                                <RequestAddressCell t={t} title={'cart.fields.fname'} value={billing.fname}/>
                                <RequestAddressCell t={t} title={'cart.fields.sname'} value={billing.sname}/>
                            </Box>
                            <Box
                                sx={rowSx}
                            >
                                <RequestAddressCell t={t} title={'cart.fields.street'} value={billing.street}/>
                                <RequestAddressCell t={t} title={'cart.fields.housenr'} value={billing.house_number}/>
                            </Box>
                            <Box
                                sx={rowSx}
                            >
                                <RequestAddressCell t={t} title={'cart.fields.zip'} value={billing.plz}/>
                                <RequestAddressCell t={t} title={'cart.fields.city'} value={billing.city}/>
                            </Box>
                            <Box
                                sx={rowSx}
                            >
                                <RequestAddressCell t={t} title={'cart.fields.country'} value={billing.country}/>
                                <RequestAddressCell t={t} title={''} value={null}/>
                            </Box>
                            <Box
                                sx={rowSx}
                            >
                                <RequestAddressCell t={t} title={'cart.fields.email01'} value={billing.email01}/>
                                <RequestAddressCell t={t} title={'cart.fields.email02'} value={billing.email02}/>
                            </Box>
                            <Box
                                sx={rowSx}
                            >
                                <RequestAddressCell t={t} title={'cart.fields.phone01'} value={billing.phone01}/>
                                <RequestAddressCell t={t} title={'cart.fields.phone02'} value={billing.phone02}/>
                            </Box>
                        </Box>
                        <Divider sx={{width: '100%', mt: 2}}/>
                    </Box>
                ) : (
                    <p>
                        {t('cart.request.no_billing')}
                    </p>
                )
            }
        </Box>
    );
}

function RequestProcessComponent({t, activeState, checkNewRequestState, process}) {

    const [loadState, setLoadState] = React.useState(false);

    const [IconComponent, setIconComponent] = React.useState(ErrorOutline);
    const [iconColor, setIconColor] = React.useState('red');

    // const IconComponent = requestStatus[requestState].icon || ErrorOutline;
    // const iconColor = requestStatus[requestState].color || 'red';

    React.useEffect(() => {
        setLoadState(true);
        checkNewRequestState(process.id);
    }, [process.id]);

    React.useEffect(() => {
        if (activeState === null) {
            setLoadState(true);
        } else {
            setLoadState(false);
            if (requestStatus[activeState] !== undefined && requestStatus[activeState] !== null) {
                setIconComponent(requestStatus[activeState].icon);
                setIconColor(requestStatus[activeState].color);
            }
        }
    }, [activeState]);

    if (loadState) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    width: '100%',
                    mt: 2,
                }}
            >
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                width: '100%',
                mt: 2,
            }}
        >
            <Typography variant={'h6'}>{t('cart.request.active-state')}</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'center',
                    gap: 10,
                }}
            >
                {
                    (activeState === null || activeState === undefined) ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100%',
                                justifyContent: 'center',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            <IconComponent sx={{fontSize: 50, color: iconColor}}/>
                            <Typography variant={"h6"}>{t('cart.request.states.' + activeState)}</Typography>
                        </>
                    )
                }
            </Box>
        </Box>
    )
}

function RequestProcessHistoryComponent({t, history}) {

    if (history === undefined || history === null) {
        return (
            <></>
        )
    }

    if (history.length === 0) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    width: '100%',
                    mt: 2,
                }}
            >
                <Typography variant={'h6'}>{t('cart.request.history')}</Typography>
                <p>{t('cart.request.no-history')}</p>
            </Box>
        )
    }

    return (
        <Accordion
            sx={{
                width: '100%',
                border: 'none',
                '&.MuiPaper-root': {
                    border: 'none',
                    '&:before': {
                        display: 'none',
                    },
                    '&:after': {
                        display: 'none',
                    }
                },
                boxShadow: '0px 1px 2px 1px #f0f0f0, 0px 1px 2px 1px #f0f0f0',
                mt: 2
            }}
        >
            <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                sx={{
                    transition: '0.3s',
                    boxShadow: 'none',
                    backgroundColor: '#e8e8e8',
                    borderRadius: '5px',
                    ':hover': {
                        cursor: 'pointer',
                        backgroundColor: '#c6c6c6'
                    }
                }}
            >
                <Typography variant={'h6'}>{t('cart.request.history')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        width: '100%',
                        mt: 2,
                    }}
                >
                    {
                        history.map((state, index) => {
                            const IconComponent = requestStatus[state.state].icon || ErrorOutline;

                            return (
                                <Box
                                    key={'state-' + index}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'start',
                                        width: '100%',
                                        mt: 2,
                                    }}
                                >

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'start',
                                            width: '100%',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <IconComponent sx={{fontSize: 30, color: requestStatus[state.state].color}}/>
                                        <Typography variant={'body1'}>{t('cart.request.states.' + state.state)}</Typography>
                                        <Typography variant={'body1'}>{state.created_at}</Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'start',
                                            width: '100%',
                                            justifyContent: 'start',
                                        }}
                                    >
                                        {
                                            (state.state_note !== undefined && state.state_note !== null) ? (
                                                <TextField
                                                    fullWidth
                                                    rows={3}
                                                    multiline
                                                    id="outlined-basic"
                                                    label={t("cart.request.state-note")}
                                                    variant="outlined"
                                                    type="text"
                                                    name="note"
                                                    value={state.state_note}
                                                    placeholder={t("cart.fields.notes")}
                                                    disabled={true}
                                                    sx={{
                                                        mt: 2,
                                                    }}
                                                />
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </Box>
                                </Box>
                            )
                        })
                    }
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

function RequestOrderComponent({t, orders, preCreation}) {
    return (
        <>
            <Divider sx={{width: '100%', mt: 2}}/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    width: '100%',
                    mt: 2,
                }}
            >
                <Accordion
                    sx={{
                        width: '100%',
                        border: 'none',
                        '&.MuiPaper-root': {
                            border: 'none',
                            '&:before': {
                                display: 'none',
                            },
                            '&:after': {
                                display: 'none',
                            }
                        },
                        boxShadow: '0px 1px 2px 1px #f0f0f0, 0px 1px 2px 1px #f0f0f0',
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        sx={{
                            transition: '0.3s',
                            boxShadow: 'none',
                            backgroundColor: '#e8e8e8',
                            borderRadius: '5px',
                            ':hover': {
                                cursor: 'pointer',
                                backgroundColor: '#c6c6c6'
                            }
                        }}
                    >
                        <Typography variant={'h6'}>{t('cart.request.orders')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                width: '100%',
                                gap: 2,
                            }}
                        >
                            {
                                orders.map((order, index) => {
                                    const person = order.order;
                                    const items = order['order-details'];

                                    return (
                                        <Box
                                            key={'order-' + index}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '100%',
                                            }}
                                        >
                                            <Typography variant={'body1'}>{person.fname} {person.sname}</Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'start',
                                                    width: '100%',
                                                    gap: 2,
                                                }}
                                            >
                                                {
                                                    items.map((item, index) => {
                                                        return (
                                                            <Box
                                                                key={'order-item-' + index}
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    justifyContent: 'start',
                                                                    width: '100%',
                                                                    alignItems: 'start',
                                                                    gap: '5'
                                                                }}
                                                            >
                                                                <Box sx={{ width: '10px' }}></Box>
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        alignItems: 'start',
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            justifyContent: 'space-between',
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <Typography variant={'body1'}>{item.product_name}</Typography>
                                                                        <Typography variant={'body1'}>{item.product_price} €</Typography>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            justifyContent: 'start',
                                                                            pl: 2,
                                                                        }}
                                                                    >
                                                                        {
                                                                            (item['booking-rent']['additional-persons'] !== undefined && true && item['booking-rent']['additional-persons'].length > 0) ? (
                                                                                <Typography variant={'body2'}>{t('cart.request.order.amount-persons')}: {item['booking-rent']['additional-persons'].length + 1}</Typography>
                                                                            ) : (
                                                                                <></>
                                                                            )
                                                                        }
                                                                        <Typography variant={'body2'}>{t('cart.request.order.amount-days')}: {item['booking-rent']['amount_days']}</Typography>
                                                                        <Typography variant={'body2'}>{t('cart.request.order.date-selection')}: {item['booking-rent'].start_date_str} - {item['booking-rent'].end_date_str}</Typography>
                                                                        {
                                                                            (item['booking-rent']['item-times'] !== undefined && true && item['booking-rent']['item-times'].length > 0) ? (
                                                                                <Box
                                                                                    sx={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'column',
                                                                                        justifyContent: 'start',
                                                                                        pl: 2,
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        item['booking-rent']['item-times'].map((time, index) => {
                                                                                            return (
                                                                                                <Typography variant={'body2'} key={'time-' + index}>{time.date_str}: {time.start_time_str} - {time.end_time_str}</Typography>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Box>
                                                                            ) : (
                                                                                <></>
                                                                            )
                                                                        }
                                                                    </Box>
                                                                    {
                                                                        (item['booking-rent']['additional-products'] !== undefined && true && item['booking-rent']['additional-products'].length > 0) ? (


                                                                                item['booking-rent']['additional-products'].map((product, index) => {
                                                                                    return (
                                                                                        <Box
                                                                                            key={'br-add-pr-' + index}
                                                                                            sx={{
                                                                                                display: 'flex',
                                                                                                flexDirection: 'row',
                                                                                                justifyContent: 'space-between',
                                                                                                width: '100%',
                                                                                            }}
                                                                                        >
                                                                                            <Typography variant={'body1'}>{product.additional_name}</Typography>
                                                                                            <Typography variant={'body1'}>{product.calculated_price_str} €</Typography>
                                                                                        </Box>
                                                                                    )
                                                                                })

                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </Box>
                                                            </Box>
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                            <Divider sx={{width: '100%', mt: 2}}/>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography variant={'body1'}>{t('cart.request.total')}:</Typography>
                                <Typography variant={'body1'}>{preCreation.price} €</Typography>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    )
}

function RequestChatComponent({t, chat, processId}) {

    const [allMessages, setAllMessages] = React.useState(chat);
    const [pageStyles, setPageStyles] = React.useState(null);
    const [noteTxt, setNoteTxt] = React.useState('');
    const [sendingNote, setSendingNote] = React.useState(false);
    const [sentNoteSuccess, setSentNoteSuccess] = React.useState(false);
    const [sentNoteFail, setSentNoteFail] = React.useState(false);
    const messagesContainerRef = React.useRef(null);
    const cartAPI = new CartAPI();

    const scrollToBottom = () => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    };

    const scrollMsg = () => {
        setTimeout(() => {
            scrollToBottom();
        }, 0);
    }

    const getNewMessages = async () => {
        const [success, response] = await cartAPI.getNotes(processId);
        if (success) {
            setAllMessages(response.notes);
        }
    }

    React.useEffect(() => {
        const loadPageStyles = async () => {
            const pageStyles = await LoadPageStyles();
            if (pageStyles !== null) {
                setPageStyles(pageStyles);
            }
        }
        loadPageStyles();
    }, []);

    React.useEffect(() => {
        scrollMsg();
    }, [allMessages]);

    React.useEffect(() => {
        scrollMsg();
    })

    React.useEffect(() => {
        // load every 30 seconds new messages
        const interval = setInterval(async () => {
            await getNewMessages();
        }, 60000);

        return () => clearInterval(interval);
    }, [getNewMessages])

    const handleNoteSentResponse = (success) => {
        if (success) {
            setSentNoteSuccess(true);
            setSentNoteFail(false);
            setNoteTxt('');
            setTimeout(() => {
                setSentNoteSuccess(false);
            }, 5000);
        } else {
            setSentNoteSuccess(false);
            setSentNoteFail(true);
            setTimeout(() => {
                setSentNoteFail(false);
            }, 5000);
        }
    }

    // interactions with backend / cartAPI
    const sendNote = async () => {
        if (noteTxt !== '') {
            setSendingNote(true);
            const [success, response] = await cartAPI.sendRequestNote({
                note: noteTxt,
                process_id: processId,
            })
            handleNoteSentResponse(success);
            if (success) {
                const notes = response.notes;
                setAllMessages(notes);
                setSendingNote(false);
            }else {
                setSendingNote(false);
            }
        }
    }

    const handleNoteTxtChange = (e) => {
        setNoteTxt(e.target.value);
    }

    if (pageStyles === null) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    width: '100%',
                    mt: 2,
                }}
            >
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                width: '100%',
                mt: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'center',
                }}
            >
                <Typography variant={'h6'}>{t('cart.request.message.title')}</Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    border: '1px solid #e0e0e0',
                    borderRadius: '5px',
                    minHeight: '200px',
                    maxHeight: '500px',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    ref={messagesContainerRef}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        width: '100%',
                        gap: 2,
                        py: 1,
                        mb: 1,
                        overflowY: 'auto',
                    }}
                >
                    {
                        allMessages.map((message, index) => {
                            return (
                                <Box
                                    key={'message-' + index}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: (message.note_created_by === 'customer') ? 'end' : 'start',
                                        justifyContent: (message.note_created_by === 'customer') ? 'end' : 'start',
                                        width: '100%',
                                        gap: 2,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            background: (message.note_created_by === 'customer') ? '#f0f0f0' : pageStyles.header.backgroundColor,
                                            color: (message.note_created_by === 'customer') ? 'black' : pageStyles.header.textColor,
                                            borderRadius: '5px',
                                            minWidth: '100px',
                                            maxWidth: '80%',
                                            p: 1.1,
                                            mx: 1
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: 5,
                                            }}
                                        >
                                            <Typography variant={'body1'} sx={{ fontSize: '13px' }}>{t('cart.request.message.sent.' + message.note_created_by)}</Typography>
                                            <Typography variant={'body1'} sx={{ fontSize: '13px' }}>{message.created_at}</Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: 5,
                                            }}
                                        >
                                            <Typography variant={'body2'}>{message.note}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            )
                        })
                    }
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        gap: 2
                    }}
                    aria-disabled={sendingNote}
                >
                    <TextField
                        fullWidth
                        rows={3}
                        multiline
                        id="outlined-basic"
                        label={t("cart.request.state-note")}
                        variant="outlined"
                        type="text"
                        name="note"
                        placeholder={t("cart.fields.notes")}
                        sx={{
                            mb: 1,
                            ml: 1,
                        }}
                        value={noteTxt}
                        onChange={handleNoteTxtChange}
                    />
                    {
                        (sendingNote) ? (
                            <Button
                                size={'large'}
                                variant={'contained'}
                                sx={{
                                    mb: 1,
                                    mr: 1,
                                    backgroundColor: '#4f76d6',
                                    color: 'white',
                                    ':hover': {
                                        backgroundColor: '#768cca',
                                    },
                                    '&:disabled': {
                                        backgroundColor: '#4f76d6',
                                    },
                                }}
                                disabled
                            >
                                <CircularProgress
                                    size={30}
                                    sx={{
                                        color: '#ffffff',
                                    }}
                                />
                            </Button>
                        ) : (
                            <Button
                                size={'large'}
                                variant={'contained'}
                                sx={{
                                    mb: 1,
                                    mr: 1,
                                    backgroundColor: (sentNoteSuccess) ? '#329029' : (sentNoteFail) ? '#d55555' : '#4f76d6',
                                    color: 'white',
                                    ':hover': {
                                        backgroundColor: '#768cca',
                                    },
                                    '&:disabled': {
                                        backgroundColor: (sentNoteFail) ? '#d55555' : '#b7c7dc',
                                    }
                                }}
                                onClick={sendNote}
                                disabled={(noteTxt === '') ? true : (sentNoteSuccess) ? false : (sentNoteFail)}
                            >
                                {
                                    (sentNoteSuccess)
                                        ? <CheckCircleOutline size={30} sx={{color: '#ffffff', fontSize: 30,}} />
                                        : (sentNoteFail) ? <ErrorOutline size={30} sx={{color: '#ffffff', fontSize: 30,}} />
                                            :
                                            <Send sx={{
                                                fontSize: 30,
                                                ':hover': {
                                                    cursor: 'pointer',
                                                },
                                                color: '#ffffff',
                                            }} />
                                }
                            </Button>
                        )
                    }
                </Box>

            </Box>
        </Box>
    )
}

export {
    RequestAddressComponent,
    RequestAddressCell,
    RequestProcessComponent,
    RequestProcessHistoryComponent,
    RequestOrderComponent,
    RequestChatComponent,
};