import React, {useEffect} from 'react';
import {Box, CircularProgress} from "@mui/material";
import {useMobileViewContext} from "../globals/MobileViewContext";


function CarouselItem({imgUrl, imgTitle}) {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // MaxWidth: '300px',
            // MaxHeight: '300px',
            width: '500px',
            height: '300px',
            borderRadius: '10px',
        }}>
            <img src={imgUrl} alt={imgTitle}
                style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                }}
            ></img>
        </Box>
    );
}


function AutoplayCarousel() {

    const [cardDetails, setCardDetails] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);

    const [isMobile, setIsMobile] = React.useState(false);
    const [centerIndex, setCenterIndex] = React.useState(0);

    const {viewType} = useMobileViewContext();

    useEffect(() => {
        if (viewType === 'mobile' || viewType === 'tablet') {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [viewType]);

    const fetchData = async () => {
        const response = await fetch('/template/carousel-config.json');
        const data = await response.json();
        setCardDetails(data);
        setIsLoaded(true);
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (!isLoaded && cardDetails === null) {
        return <Box>
            <CircularProgress />
        </Box>
    }

    return (
        <Box
            sx={{
                overflow: 'hidden',
                width: '100%',
                height: '100%',
                borderTop: '1px solid #e0e0e0',
                borderBottom: '1px solid #e0e0e0',
            }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                position: 'relative',
                height: '100%',
                width: (isMobile) ? '800%' : '400%',
                left: 0,
                animation: (isMobile) ? 'slide 10s linear infinite' : 'slide 20s linear infinite',
            }}>
                {Object.keys(cardDetails).map((detailKey) => {
                    return (
                        <CarouselItem
                            imgUrl={cardDetails[detailKey].img}
                            imgTitle={cardDetails[detailKey].title}
                            key={"carousel-item-0-" + detailKey}
                        ></CarouselItem>
                    );
                })}
                {/*{Object.keys(cardDetails).map((detailKey) => {*/}
                {/*    return (*/}
                {/*        <CarouselItem*/}
                {/*            imgUrl={cardDetails[detailKey].img}*/}
                {/*            imgTitle={cardDetails[detailKey].title}*/}
                {/*            key={"carousel-item-1-" + detailKey}*/}
                {/*        ></CarouselItem>*/}
                {/*    );*/}
                {/*})}*/}
            </Box>
        </Box>
    );
}

export default AutoplayCarousel;