import React from 'react';
import {useTranslation} from "react-i18next";
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Typography} from "@mui/material";
import SimpleAssistantAPI from "../../../../../common/handlers/api-handlers/SimpleAssistantAPI";
import {useError} from "../../../../../common/errors/ErrorProvide";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ProductCalendar from "./product/ProductCalendar";
import {type} from "@testing-library/user-event/dist/type";
import {useCalendarSelectionContext} from "../../globals/CalendarContext";
import {usePersonContext} from "../../globals/PersonContext";
import {useCartContext} from "../../../../../common/providers/CartContext";


const initAccordionSx = {
    transition: '0.3s',
    backgroundColor: '#f9f9f9',
    '&.Mui-expanded': {
        borderBottom: '1px solid #f0f0f0',
    },
    ':hover': {
        cursor: 'pointer',
        backgroundColor: '#f0f0f0'
    },
}

const initProductCardSx = {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    flex: '100%',
}

function ProductComponent({itemData, bookable=false}) {
    const {i18n, t} = useTranslation();

    let currentLanguage = i18n.language;
    let fallbackLanguage = i18n.options.fallbackLng[0];
    const [accordionExpanded, setAccordionExpanded] = React.useState(false);
    const [contentData, setContentData] = React.useState(null);
    const [apiLoading, setApiLoading] = React.useState(false);
    const API = new SimpleAssistantAPI();
    const [isMobile] = React.useState(false);
    const [accordionSx, setAccordionSx] = React.useState(initAccordionSx);
    const [productCardSx, setProductCardSx] = React.useState(initProductCardSx);
    const [preCalculatedPrice, setPreCalculatedPrice] = React.useState(t("simple-assistant.errors.price-calculation.cannot-calculate-price"));
    const {selectedDates} = useCalendarSelectionContext();
    const {showError} = useError();
    const {activePerson} = usePersonContext();
    const {addSimpleAssistantToCart, setCartUpdated} = useCartContext();
    const [priceIsLoading, setPriceIsLoading] = React.useState(false)

    const handleCartAdd = async () => {
        // if (selectedDates === null || selectedDates === undefined) {
        //     showError(t("simple-assistant.errors.price-calculation.no-dates-selected"));
        //     return;
        // }
        //
        // if (selectedDates.start === undefined || selectedDates.end === undefined) {
        //     showError(t("simple-assistant.errors.price-calculation.no-dates-selected"));
        //     return;
        // }
        //
        // const data = {
        //     product_id: itemData.id,
        //     person_id: activePerson,
        //     start: selectedDates.start,
        //     end: selectedDates.end
        // }
        // const success = await addSimpleAssistantToCart(data);
        // console.log('SUCCESS OR NOT', success)
        // if (success) {
        //     setCartUpdated(true);
        // }
        const data = {
            product_id: itemData.id,
            person_id: activePerson,
            start: selectedDates.start,
            end: selectedDates.end
        }
        const success = await addSimpleAssistantToCart(data);
    }

    const fetchContentData = async () => {
        setApiLoading(true);
        const [success, response] = await API.getProduct(itemData.id);
        if (success) {
            setContentData(response.data);
        } else {
            showError(response)
        }
        setApiLoading(false);
    }

    const fetchPreCalulatedPrice = async (
        data = {
            "product_id": null,
            "person_id": null,
        }
    ) => {
        if (data.selection.start === undefined && data.selection.end === undefined) {
            data = {
                "product_id": itemData.id,
                "person_id": null,
            };
        }
        if (data.person_id === null) {
            data.person_id = activePerson;
        }
        setPriceIsLoading(true)
        const [success, response] = await API.calcPrice(data);
        if (success) {
            const data = response.data;
            if (selectedDates === null || selectedDates === undefined) {
                setPreCalculatedPrice("0,00")
            }
            if (data.price !== null || data.price !== undefined || isNaN(data.price)) {
                setPreCalculatedPrice(data.price);
            }
        } else {
            if (response.data.context.code !== 10019) {
                showError(response.msg);
                setPreCalculatedPrice(t("simple-assistant.errors.price-calculation.cannot-calculate-price"));
            } else {
                const t_key = "simple-assistant.errors.price-calculation." + response.data.message;
                setPreCalculatedPrice(t(t_key));
            }
        }
    };

    const fetchAndSetPreCalculatedPrice = async (datesSelected) => {
        const priceField = document.getElementById("sb-product-card-price-value");
        if (priceField === undefined) {
            return;
        }

        if (datesSelected === null || datesSelected === undefined) {
            datesSelected = {}
        }

        await fetchPreCalulatedPrice({
            product_id: itemData.id,
            person_id: null,
            selection: datesSelected
        }).then(() => {
            setPriceIsLoading(false);
        });
    }

    React.useEffect(() => {
        if (accordionExpanded) {
            fetchContentData();
        }
    }, [accordionExpanded]);

    React.useEffect(() => {
        if (accordionExpanded) {
            fetchAndSetPreCalculatedPrice(selectedDates);
        }
    }, [selectedDates]);

    React.useEffect(() => {
        let newAccordionSx = initAccordionSx;

        if (bookable === false) {
            newAccordionSx.backgroundColor = '#a4a4a4';
            newAccordionSx.overflow = 'hidden';
        }
        setAccordionSx(newAccordionSx);
    }, []);

    const openAccordion = (e, expanded) => {
        if (expanded) {
            setAccordionExpanded(true);
        } else {
            setAccordionExpanded(false);
        }
    }

    return (
        <Accordion
            key={itemData.id}
            sx={{ width: '100%' }}
            onChange={openAccordion}
        >
            <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={accordionSx}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: '3px'
                    }}
                >
                    <Box>{
                        (itemData.name[currentLanguage] !== undefined) ? itemData.name[currentLanguage] : itemData.name[fallbackLanguage]
                    }</Box>
                    {
                        (itemData["amount_inner"] !== undefined)
                            ? <Box>({itemData["amount_inner"]})</Box>
                            : <></>
                    }
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                {
                    (apiLoading || contentData === null)
                        ?
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%',
                                my: 3
                            }}
                        >
                            <CircularProgress/>
                        </Box>
                        :
                        <Box
                            sx={productCardSx}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '1rem',
                                    width: '50%',
                                    padding: '1rem'
                                }}
                            >
                                <p>{itemData.description[currentLanguage]}</p>
                                <Typography
                                    variant="body1"
                                    color="text.primary"
                                    id={"sb-product-card-amount-days-value"}
                                >
                                    {t('product.nothing-selected')}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    id={"sb-product-card-price-value"}
                                >
                                    {
                                        (priceIsLoading)
                                            ? <CircularProgress size={"1rem"}/>
                                            : preCalculatedPrice
                                    }
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCartAdd}
                                    sx={{
                                        maxWidth: '15rem'
                                    }}
                                >
                                    Add to cart
                                </Button>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '1rem',
                                    width: '50%',
                                    padding: '1rem'
                                }}
                            >
                                <ProductCalendar calendarBooking={contentData['product']['calendar-booking']} isMobile={isMobile}/>
                            </Box>
                        </Box>
                }
            </AccordionDetails>
        </Accordion>
    )
}

export default ProductComponent;