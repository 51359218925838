import React from 'react';
import {Box, Grid} from "@mui/material";
import AddressField from "./components/AddressField";
import CartInventory from "./components/CartInventory";
import {useTranslation} from "react-i18next";
import PaymentSelection from "./components/PaymentSelection";
import CartStepper from "./CartStepper";
import {loadStripe} from "@stripe/stripe-js";

function Cart(props) {

    const {t} = useTranslation();

    const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 800;
    const [isMobile, setIsMobile] = React.useState(false);
    const [address, setAddress] = React.useState({
        'fname': '',
        'sname': '',
        'street': '',
        'housenr': '',
        'plz': '',
        'city': '',
        'country': '',
        'phone01': '',
        'phone02': '',
        'email01': '',
        'note': '',
        'country-others': false,
        'country-other': '',
        'newsletter': false,
        'terms': false,
    });

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        setIsMobile(width < breakpoint);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [window.innerWidth]);


    const handleAddressChange = (e) => {
        setAddress({
            ...address,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <CartStepper />
    )

    if (isMobile) {
        return (
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <CartInventory t={t}/>
                    </Grid>
                    <Grid item xs={12}>
                        <AddressField t={t} address={address} handleAddressChange={handleAddressChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <PaymentSelection t={t}/>
                    </Grid>
                </Grid>
            </Box>
        );
    } else {
        return (
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={6}>
                        <CartInventory t={t}/>
                    </Grid>
                    <Grid item xs={4}>
                        <AddressField t={t} address={address} handleAddressChange={handleAddressChange}/>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                        <PaymentSelection t={t}/>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </Box>
        );
    }
}

export default Cart;