import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import {useMobileViewContext} from "../../../../../../templates/simpleAssistant/globals/MobileViewContext";
import {loadStripe} from "@stripe/stripe-js";
import PaymentAPI from "../../../../../../../common/handlers/api-handlers/PaymentAPI";

function StripeCheckoutForm({address}) {

    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const {viewType} = useMobileViewContext();

    const returnUrl = process.env.REACT_APP_KB_PAYMENT_RETURN_URL


    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {

        localStorage.setItem('pCall', {'tab': 2, 'called': true, "address": address });

        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                // get REACT_APP_KB_PAYMENT_RETURN_URL from .env
                return_url: returnUrl
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error) {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        type: 'payment',
        layout: (viewType === 'mobile') ? 'accordion' : 'tabs',
    }

    return (

                <form id="payment-form" onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', width: '100%', minWidth: '0' }}>

                    <PaymentElement id="payment-element" options={paymentElementOptions}  // Ensure the form element is full-width
                    />
                    <button disabled={isLoading || !stripe || !elements} id="submit">
                        <span id="button-text">
                          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
                        </span>
                    </button>
                    {/* Show any error or success messages */}
                    {message && <div id="payment-message">{message}</div>}
                </form>

    );

    // return (
    //     <div className="stripe-payment-api" style={{ width: '100%', maxWidth: '700px' }}>
    //         {clientSecret && (
    //             <Elements options={options} stripe={stripePromise}>
    //                 <StripeCheckoutForm address={address} />
    //             </Elements>
    //         )}
    //     </div>
    // );

}

export default StripeCheckoutForm;