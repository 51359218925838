import React, {useEffect} from 'react';
import CalendarTemplate from "./CalendarTemplate";
import {useCalendarSelectionContext} from "../globals/CalendarContext";

function CalendarSelfSelection({ startDate, t, calendarData, isMobile }) {

    /**
     * Handle date selection in calendar with following css classes
     * one selected: first date and last date selected.
     * two selected: first date and last date selected by first click on first date and second click on last date.
     * third selected: first date and last date cleared by clicking on any date.
     *
     * To select one date, click on the date.
     * To select two dates, click on the first date and then click on the last date.
     * To clear the selection, click on any date.
     *
     * @param e
     * @param day
     */

    const [firstDate, setFirstDate] = React.useState(null);
    const [lastDate, setLastDate] = React.useState(null);
    const {putSelectedDates, cleanUp} = useCalendarSelectionContext();


    /**
     * The cleanUpCalendar function removes the selected class from all elements with the class of 'selected'
     * and removes the selected-date-change class from all elements with that class.

     *
     *
     * @return Nothing
     *
     * @docauthor Trelent
     */
    const cleanUpCalendar = () => {
        let calendarTemplate = document.getElementById('sb-calendar-default-template');
        if (!calendarTemplate) {
            return;
        }
        let calendarFields = calendarTemplate.getElementsByClassName('selected');
        calendarFields = Array.from(calendarFields);
        calendarFields.forEach((field) => {
            field.classList.remove('selected');
            field.classList.remove('selected-date-change');
        });

        let calAmountDaysField = document.getElementById("sb-product-card-amount-days-value");
        if (calAmountDaysField !== undefined) {
            calAmountDaysField.innerText = t('product.nothing-selected');
        }
    }

    /**
     * The collectOtherDates function takes two dates as arguments and returns an array of all the dates in between them.
     *
     *
     * @param firstDate Set the first date in the calendar
    const setfirstdate = (firstdate) =&gt; {
        if (firstdate) {
            let fieldid = 'sb-calendar-date-field-' + firstdate
     * @param lastDate Set the last date in the state
    const setlastdate = (lastdate) =&gt; {
        if (lastdate) {
            let fieldid = 'sb-calendar-date-field-' + lastdate
     *
     * @return An array of dates between the first and last date
     *
     * @docauthor Trelent
     */
    const collectOtherDates = (firstDate, lastDate) => {
        let otherDates = [];
        if (firstDate && lastDate) {
            if (firstDate.isAfter(lastDate)) {
                let temp = firstDate;
                firstDate = lastDate;
                lastDate = temp;
            }
            let currentDate = firstDate;
            while (currentDate.isBefore(lastDate)) {
                currentDate = currentDate.add(1, 'day');
                otherDates.push(currentDate);
            }
            otherDates.push(currentDate);
        } else {
            otherDates = [];
        }

        let return_dates = true;
        otherDates.map((date) => {
            let fieldId = 'sb-calendar-date-field-' + date.format('YYYY-MM-DD');
            let field = document.getElementById(fieldId);
            if (field && !field.classList.contains('free') && !field.classList.contains('less')) {
                return_dates = false;
            }
        });

        if (!return_dates) {
            cleanUpCalendar();
            setFirstDate(null);
            setLastDate(null);
            putSelectedDates();
            return [];
        } else {
            putSelectedDates({
                'start': firstDate.format('YYYY-MM-DD'),
                'end': lastDate.format('YYYY-MM-DD'),
            });
            return otherDates;
        }
    }

    const selectDate = (day) => {
        const fieldId = 'sb-calendar-date-field-' + day.format('YYYY-MM-DD');
        let field = document.getElementById(fieldId);
        if (field) {
            field.classList.add('selected');
            field.classList.add('selected-date-change');
        } else {

        }
    }

    const setAmountDaysValue = (amountDays, empty) => {
        let calAmountDaysField = document.getElementById("sb-product-card-amount-days-value");
        if (calAmountDaysField !== undefined) {
            if (empty) {
                calAmountDaysField.innerText = t('product.nothing-selected');
            } else {
                calAmountDaysField.innerText = (amountDays.toString() + ' ' + t('calendar.days-string')).toString();
            }
        }
    }

    const handleDateSelect = (e, day) => {
        if (firstDate !== null && lastDate !== null) {
            setFirstDate(null);
            setLastDate(null);
            return false;
        } else {
            if (e.target.classList.contains('free') || e.target.classList.contains('less')) {
                if (!firstDate) {
                    setFirstDate(day);
                } else {
                    setLastDate(day);
                }
            }
            return true;
        }
    }

    useEffect(() => {
        if (firstDate === null && lastDate === null) {
            let calendarTemplate = document.getElementById('sb-calendar-default-template');
            if (!calendarTemplate) {
                return;
            }
            let calendarFields = calendarTemplate.getElementsByClassName('selected-date-change');
            calendarFields = Array.from(calendarFields);
            calendarFields.forEach((field) => {
                field.classList.remove('selected');
                field.classList.remove('selected-date-change');
            });
            putSelectedDates();
            setAmountDaysValue(0, true);

        } else {
            if (firstDate && !lastDate) {
                selectDate(firstDate);
                setAmountDaysValue(1, false);
                putSelectedDates({
                    'start': firstDate.format('YYYY-MM-DD'),
                    'end': firstDate.format('YYYY-MM-DD'),
                });
            } else if (firstDate && lastDate) {
                let otherDates = collectOtherDates(firstDate, lastDate);
                if (otherDates.length === 0) {
                    return;
                }

                // query the server for the availability of the selected dates
                // if the dates are available, then select the returned dates
                // TODO: implement the server query
                // 1. create a RESTBuilder instance
                // 2. send the request
                // 3. handle the response
                // 4. select the dates
                // 5. if the dates are not available, then clean up the calendar
                // 6. set firstDate and lastDate to the returned first and last dates

                otherDates.forEach((date) => {
                    selectDate(date);
                });
                selectDate(firstDate);
                selectDate(lastDate);
                setAmountDaysValue(otherDates.length, false);
            } else {
                cleanUpCalendar();
            }
        }
    }, [firstDate, lastDate]);

    useEffect(() => {
        if (cleanUp) {
            cleanUpCalendar();
            setFirstDate(null);
            setLastDate(null);
        }
    }, [cleanUp]);

    useEffect(() => {
        cleanUpCalendar();
        setFirstDate(null);
        setLastDate(null);
    }, [])

    return (
        <CalendarTemplate startDate={startDate} t={t} calendarData={calendarData} isMobile={isMobile} handleDateSelect={handleDateSelect} />
    );
}

export default CalendarSelfSelection;