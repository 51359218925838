import {Outlet, Route} from "react-router-dom";
import React from "react";
import Cart from "./cart/Cart";
import CartPrepared from "./cart/CartPrepared";
import CartStepper from "./cart/CartStepper";

export const CartRoutes = [
    <Route key={"cart"} path={"/cart"} element={<Cart />} />,
    <Route key={"cart-prepared"} path={"/cart/prepared/*"} element={<CartPrepared />} />,
    // <Route key={"payment"} path={"/cart/payment"} element={<Cart />} />,
    // <Route key={"checkout"} path={"/cart/checkout"} element={<Cart />} />,
    <Route key={"thank-you"} path={"/cart/thank-you"} element={<CartStepper directThankYou={true}/>} />,
]

function CartPages(props) {
    return (
        <Outlet />
    );
}

export default CartPages;