import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import {
    Backdrop,
    Box,
    CircularProgress, Stack,
} from "@mui/material";
import HeaderComponent from "./header/HeaderComponent";
import PersonComponent from "./PersonComponent";
import SelectionComponent from "./selection/SelectionComponent";
import {usePersonContext} from "./globals/PersonContext";
import {LoadTemplateFile} from "../TemplateLoader";
import {ProductProvider} from "./globals/ProductContext";
import {useMobileViewContext} from "./globals/MobileViewContext";
import AutoplayCarousel from "./footer/AutoplayCarousel";
import {useCartContext} from "../../../common/providers/CartContext";
import SliderComponent from "./footer/SliderComponent";
import {useTranslation} from "react-i18next";

const propTypes = {
    // template
    template: PropTypes.object,

    // view states
    isMobile: PropTypes.bool,
    isLoading: PropTypes.bool,
    isProcessing: PropTypes.bool,

    // person functionalities
    personChange: PropTypes.func,
    personAdd: PropTypes.func,
    personDelete: PropTypes.func,
    personSelect: PropTypes.func,
    personGet: PropTypes.func,

    // data for functionalities
    personData: PropTypes.object,

    // selection functionalities
    addToSelection: PropTypes.func,
    removeFromSelection: PropTypes.func,
    clearSelection: PropTypes.func,
    getSelection: PropTypes.func,
};

function ProcessorComponent(props) {

    const [isLoading, setIsLoading] = React.useState(false);
    const [template, setTemplate] = React.useState(null);
    const [newViewType, setNewViewType] = React.useState('desktop');
    const {viewType} = useMobileViewContext();
    const {cartUpdated, setCartUpdated} = useCartContext();

    const {i18n, t} = useTranslation();
    const currentLanguage = i18n.language;

    React.useEffect(() => {
        setNewViewType(viewType);
    }, [viewType]);

    const {
        persons,
        getPersons,
        personsLoading,
    } = usePersonContext();


    React.useEffect(() => {
        setIsLoading(true);
        LoadTemplateFile({isSimpleAssistant: true}).then((data) => {
            setTemplate(data);
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {

        if (personsLoading) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }

    }, [persons, personsLoading]);

    useEffect(() => {
        if (cartUpdated) {
            // scroll to top
            window.scrollTo(0, 0);
            setCartUpdated(false);
        }
    }, [cartUpdated]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
            }}
        >
            {
                // Top left are persons
                // Top right are logo and price as well as button to cart
                // Next full width is a row of buttons for each person
                // Next full width is all selectionable items as accordions listed
            }
            <Box
                sx={{
                    display: 'flex',
                    width: '100%'
                }}
            >
                <HeaderComponent persons={persons} template={template}/>
            </Box>

            <PersonComponent persons={persons} template={template} currentLanguage={currentLanguage}/>
            <ProductProvider >
                <SelectionComponent template={template} />
            </ProductProvider>

            <Box
                sx={{
                    mt: 5,
                    mb: 1,
                    width: '100%'
                }}
            >
                <SliderComponent />
            </Box>

            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading || personsLoading || template === null}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

        </Box>
    );
}

export default ProcessorComponent;