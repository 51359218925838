import React from 'react';
import {Box} from "@mui/material";
import {useMobileViewContext} from "../globals/MobileViewContext";

function ProductPlaceholder({catName}) {

    const [isMobile, setIsMobile] = React.useState(false);

    const {viewType} = useMobileViewContext();

    React.useEffect(() => {
        if (viewType === 'mobile' || viewType === 'tablet') {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [viewType]);

    if (isMobile) {
        return (<></>)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '50px',
                width: '100%',
            }}
        >
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        width: '400px',
                        height: '100%',
                        minHeight: '150px',
                        backgroundColor: 'grey.300',
                        borderRadius: '10px',
                        p: 1,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                </Box>
            </Box>
        </Box>
    );
}

export default ProductPlaceholder;