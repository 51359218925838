import React from 'react';
import {Box, Card, Divider} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
    RequestAddressComponent, RequestChatComponent,
    RequestOrderComponent,
    RequestProcessComponent, RequestProcessHistoryComponent
} from "./requestComponents/RequestComponents";
import RequestInteractions from "./requestComponents/RequestInteractions";
import {useCartContext} from "../../../../../common/providers/CartContext";

function RequestCheck({t, requestData}) {

    const [activeState, setActiveState] = React.useState(null);
    const [history, setHistory] = React.useState(null);
    const {requestState, checkRequestState, requestHistory} = useCartContext();

    const checkNewRequestState = (processId) => {
        checkRequestState(processId);
    }

    React.useEffect(() => {
        setActiveState(requestState);
        setHistory(requestHistory);
    }, [requestState, requestHistory]);

    if (requestData === null) {
        return (
            <Card
                variant={"outlined"}
                sx={{p: 2, maxWidth: '700px', width: '100%'}}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant={"h6"}>{t('cart.request.title')}</Typography>
                    <p>
                        {t('cart.request.no_billing')} <br/>
                    </p>
                </Box>
            </Card>
        )
    }

    return (
        <Card
            variant={"outlined"}
            sx={{p: 2, maxWidth: '700px', width: '100%'}}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography variant={"h5"}>{t('cart.request.title')}</Typography>
                <Divider sx={{width: '100%'}}/>
            </Box>
            {
                (requestData !== undefined && true && requestData.billing !== undefined) ? (
                    <RequestAddressComponent t={t} billing={requestData.billing}/>
                ) : (
                    <></>
                )
            }
            {
                (requestData !== undefined && true && requestData.process !== undefined) ? (
                    <>
                        <RequestProcessComponent t={t} activeState={activeState} checkNewRequestState={checkNewRequestState} process={requestData.process}/>
                    </>

                ) : (
                    <></>
                )
            }
            {
                (requestData !== undefined && true && requestData.process !== undefined) ?
                    <RequestInteractions t={t} checkNewRequestState={checkNewRequestState} activeState={activeState} process={requestData.process}/>
                    :
                    <></>
            }
            {
                (requestData !== undefined && true && requestData.process !== undefined && history !== undefined && history !== null) ? (
                    <RequestProcessHistoryComponent t={t} history={history}/>
                ) : (
                    <></>
                )
            }
            {
                (requestData !== undefined && true && requestData['billing-orders'] !== undefined && requestData['pre-creation'] !== undefined) ? (
                    <RequestOrderComponent t={t} orders={requestData['billing-orders']} preCreation={requestData['pre-creation']}/>
                ) : (
                    <></>
                )
            }
            {
                (requestData !== undefined && true && requestData.process !== undefined && requestData.process.notes !== undefined) ? (
                    <RequestChatComponent t={t} chat={requestData.process.notes} processId={requestData.process.id}/>
                ) : (
                    <></>
                )
            }
        </Card>
    );
}

export default RequestCheck;