import React, { useEffect, useRef, useState } from 'react';
import { Box } from "@mui/material";
import InputField from "../../../../shopbiachleDefaultView/inputFields/InputField";
import { useTranslation } from "react-i18next";
import { useError } from "../../../../../../common/errors/ErrorProvide";

function RequiredFields({
                            setRequiredFields,
                            requiredFields,
                            requiredFieldsData,
                            setRequiredFieldsData,
                            productData
                        }) {

    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
    const [previousData, setPreviousData] = useState({});
    const debounceTimer = useRef(null);
    const { showError } = useError();

    const checkProductData = () => {
        return productData && productData.product && productData.product['required-fields'];
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequiredFieldsData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        // Clear the existing debounce timer
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }

        // Start a new debounce timer
        debounceTimer.current = setTimeout(() => {
            validateFields();
        }, 500);

        // Cleanup the timeout if the component unmounts or value changes
        return () => clearTimeout(debounceTimer.current);

    }, [requiredFieldsData]); // Dependency array ensures this runs when requiredFieldsData changes

    const validateFields = () => {
        let validationErrors = {};

        console.log('requiredFieldsData', requiredFieldsData);

        Object.keys(requiredFieldsData).forEach((key) => {
            const field = requiredFields.find(f => f.name === key);
            const value = requiredFieldsData[key];

            // Min/Max validation for number fields
            if (field?.type === 'number') {
                if (value !== '' && (value < field.min || value > field.max)) {
                    validationErrors[key] = `Value must be between ${field.min} and ${field.max}`;
                }
            }

            // Check required fields
            if (field?.required && !value && value === '') {
                validationErrors[key] = '';
            }
        });

        if (Object.keys(validationErrors).length > 0) {
            console.log('validationErrors', validationErrors);
            Object.keys(validationErrors).forEach((key) => {
                setRequiredFields(prevState => {
                    return prevState.map(field => {
                        if (field.name === key) {
                            return {
                                ...field,
                                error: {
                                    message: validationErrors[key],
                                    code: 0
                                }
                            };
                        }
                        return field;
                    });
                })
            });
        } else {
            console.log('validation passed');
            setRequiredFields(prevState => {
                return prevState.map(field => {
                    return {
                        ...field,
                        error: null
                    };
                });
            })
        }
    };

    useEffect(() => {
        if (!checkProductData()) return;

        const rF = productData.product['required-fields']['fields'];
        const rFD = productData.product['required-fields']['provided_data_set'];

        if (rF && rFD) {
            setRequiredFields(rF);
            setRequiredFieldsData(rFD);
        }
    }, [productData, setRequiredFields, setRequiredFieldsData]);

    useEffect(() => {
        setCurrentLanguage(i18n.language);
    }, [i18n.language]);

    if (!checkProductData()) {
        return null;
    }

    if (!requiredFields || requiredFields.length === 0) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: '250px',
                width: '100%',
                gap: 1,
                alignItems: 'start'
            }}

        >
            {requiredFields.map((field) => {
                field.sx = {
                    minWidth: '250px',
                    width: '100%'
                }
                return (<Box
                    key={field.id}
                >
                    <InputField
                        field={field}
                        value={requiredFieldsData[field.name]}
                        handleChange={handleChange}
                        t={t}
                        currentLanguage={currentLanguage}
                    />
                </Box>)

            })}
        </Box>
    );
}

export default RequiredFields;
