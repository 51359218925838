import APICall from "../APICall";

class ShopAPI extends APICall {

    constructor() {
        super();
    }

    async makeTestCall() {
        await super.fetchConfig();

        return await super.makeGetRequest({
            request: {
                apiType: 'test',
                route: 'get',
                additionalRouteParams: [
                    '100', '200', '300'
                ],
            },
            query: null
        });
    }

    async getPath({path=[]}) {
        await super.fetchConfig();

        return await super.makeGetRequest({
            request: {
                apiType: 'shop',
                route: 'path',
                additionalRouteParams: path,
            },
            query: null
        });
    }

    async checkAddCart({productID, dataset}) {
        await super.fetchConfig();

        return await super.makePostRequest({
            request: {
                apiType: 'shop',
                route: 'checkAddCart',
                additionalRouteParams: [productID],
            },
            data: dataset,
            query: null,
            expectErrorResponse: true,
        });
    }

    async personDataFields() {
        await super.fetchConfig();

        return await super.makeGetRequest({
            request: {
                apiType: 'person',
                route: 'personForm',
                additionalRouteParams: [],
            },
            query: null
        });
    }
}


export default ShopAPI;