import axios from "axios";
import config from "../../config/config.json";

class APICall {

    axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
    }

    constructor() {}

    async fetchConfig() {

        let data = config;
        if (this.config !== undefined) {
            return;
        }

        this.config = data;
    }

    uriBuilder({apiType, route, additionalRouteParams=null}) {

        const base = this.config.api.endpoints[apiType].base;
        const version = this.config.api.version;
        const uriRoute = this.config.api.endpoints[apiType].routes[route];
        const apiMain = this.config.main;
        const apiEnv = process.env.REACT_APP_KB_API_HOST;

        if (additionalRouteParams !== null) {
            let additionalRouteParamsString = '';

            additionalRouteParams.map((param) => {
                additionalRouteParamsString += `/${param}`;
            })

            return `${apiEnv}${apiMain}${version}${base}${uriRoute}${additionalRouteParamsString}`;
        }

        return `${apiEnv}${apiMain}${version}${base}${uriRoute}`;
    }

    queryBuilder({uri, query}) {
        let queryString = '';

        query.map((param, index) => {
            let innerQueryString = '';
            if (index === 0) {
                innerQueryString = '?';
            } else {
                innerQueryString = '&';
            }
            innerQueryString += `${param.key}=${param.value}`;
            queryString += innerQueryString;
        });
        return `${uri}${queryString}`;
    }

    filterResponse({response}) {
        const resp = response.data;
        if (resp.status === 200) {
            if (resp.data.context !== undefined) {
                return [true, resp.data.context];
            } else {
                return [false, resp.data.message];
            }
        } else {
            return [false, resp.message];
        }
    }

    filterError({error, expectErrorResponse=false, ignoreErrors=[]}) {

        if (error.response === undefined) {
            return [false, error.message];
        }
        if (error.response.data === undefined) {
            return [false, error.message];
        }

        const response = error.response.data;
        let error_msg = '';
        if (response.data.code !== undefined) {
            console.log(response.data, 'ignoring error');
            if (ignoreErrors.includes(response.data.code)) {
                console.log('ignoring error');
                return [false, [true, response.data]];
            }
            error_msg = `${response.data.code}: ${response.data.message}`;
        } else {
            error_msg = response.data.message;
        }
        if (expectErrorResponse) {
            let new_response = {
                data: response.data,
                msg: error_msg,
            }

            return [false, new_response];
        }
        return [false, error_msg];
    }

    async makePostRequest({request, data, query=null, expectErrorResponse=false, ignoreErrors = []}) {

        let uri = this.uriBuilder(request);

        const JSONdata = JSON.stringify(data);

        return axios.post(uri, JSONdata, {withCredentials: true, headers: {'Content-Type': 'application/json'}})
            .then((response) => {
                return this.filterResponse({response});
            })
            .catch((error) => {
                return this.filterError({error, expectErrorResponse, ignoreErrors});
            });
    }

    async makeGetRequest({request, query=null, expectErrorResponse=false}) {

        let uri = this.uriBuilder(request);

        if (query !== null) {
            uri = this.queryBuilder({uri, query});
        }

        return axios.get(uri, {withCredentials: true})
            .then((response) => {
                return this.filterResponse({response});
            })
            .catch((error) => {
                return this.filterError({error, expectErrorResponse});
            });
    }
}

export default APICall;
