import APICall from "../APICall";

class SimpleAssistantAPI extends APICall {

    constructor() {
        super();
    }

    async getPersons() {
        await super.fetchConfig();

        return await super.makeGetRequest({
            request: {
                apiType: 'simpleAssistant',
                route: 'persons'
            },
            query: null
        });
    }

    async addPerson(data) {
        await super.fetchConfig();

        return await super.makePostRequest({
            request: {
                apiType: 'simpleAssistant',
                route: 'persons'
            },
            data: data,
            query: null
        });
    }

    async getPerson(personId) {
        await super.fetchConfig();

        return await super.makeGetRequest({
            request: {
                apiType: 'simplerAssistant',
                route: 'person',
                additionalRouteParams: [personId],
            },
            query: null
        })
    }

    async updatePerson(data) {
        await super.fetchConfig();

        return await super.makePostRequest({
            request: {
                apiType: 'simpleAssistant',
                route: 'person',
                additionalRouteParams: [],
            },
            data: data,
            query: null,
            ignoreErrors: [10400]
        });
    }

    async getActivePerson() {
        await super.fetchConfig();

        return await super.makeGetRequest({
            request: {
                apiType: 'simpleAssistant',
                route: 'activePerson',
                additionalRouteParams: [],
            },
            query: null
        });
    }

    async updateActivePerson(personId) {
        await super.fetchConfig();

        return await super.makePostRequest({
            request: {
                apiType: 'simpleAssistant',
                route: 'activePerson',
                additionalRouteParams: [],
            },
            data: {'person_id': personId},
            query: null
        });
    }

    async getPersonContent(personId) {
        await super.fetchConfig();

        return await super.makeGetRequest({
            request: {
                apiType: 'simpleAssistant',
                route: 'personContent',
                additionalRouteParams: [personId],
            },
            query: null
        });
    }

    async getPersonContent({data, productId}) {
        await super.fetchConfig();

        return await super.makePostRequest({
            request: {
                apiType: 'simpleAssistant',
                route: 'personContent',
                additionalRouteParams: [productId],
            },
            data: data,
            query: null
        });
    }

    async removePerson(personId) {
        await super.fetchConfig();

        return await super.makePostRequest({
            request: {
                apiType: 'simpleAssistant',
                route: 'removePerson',
                additionalRouteParams: [personId],
            },
            data: {},
            query: null
        });
    }

    async getProducts(personId) {
        await super.fetchConfig();
        let checkedPersonId = personId ? personId : '';
        return await super.makeGetRequest({
            request: {
                apiType: 'simpleAssistant',
                route: 'personContent',
                additionalRouteParams: (personId) ? [checkedPersonId] : null,
            },
            query: null
        });
    }

    async getProduct(productId) {
        await super.fetchConfig();

        return await super.makeGetRequest({
            request: {
                apiType: 'simpleAssistant',
                route: 'product',
                additionalRouteParams: [productId],
            },
            query: null
        });
    }

    async calcPrice(data) {
        await super.fetchConfig();

        return await super.makePostRequest({
            request: {
                apiType: 'simpleAssistant',
                route: 'calcPrice',
            },
            data: data,
            query: null,
            expectErrorResponse: true
        });
    }

    async addToCart({personId, data}) {
        await super.fetchConfig();

        return await super.makePostRequest({
            request: {
                apiType: 'simpleAssistant',
                route: 'addToCart',
                additionalRouteParams: [personId],
            },
            data: data,
            query: null,
            expectErrorResponse: true
        });
    }

    async makeProductCheck(data) {
        await super.fetchConfig();

        return await super.makePostRequest({
            request: {
                apiType: 'simpleAssistant',
                route: 'productCheck',
            },
            data: data,
            query: null,
            expectErrorResponse: true
        })
    }
}

export default SimpleAssistantAPI;