import React, {useState} from 'react';
import {
    Box,
    Button,
    Chip,
    CircularProgress, FilledInput,
    FormControl, FormControlLabel,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select, Typography
} from "@mui/material";
import {usePersonContext} from "../../../globals/PersonContext";
import AddNewPerson from "../../../../../../common/components/person/AddNewPerson";
import {useTranslation} from "react-i18next";
import SelectInput from "@mui/material/Select/SelectInput";
import {AddCircleOutline, PlusOneRounded} from "@mui/icons-material";

function PrivateCourseTimeSelection({
                                        timeSelection,
                                        selectedTimes,
                                        timeSelectionLoading,
                                        handleTimeSelect
                                    }) {

    if (timeSelectionLoading) {
        return (
            <Box sx={{
                p: 1
            }}>
                <CircularProgress size={'1rem'}/>
            </Box>
        )
    }

    return (
        (timeSelection !== null && timeSelection !== undefined && timeSelection.length > 0)
            ? (
                <Box
                    sx={{
                        p: 1,
                        width: '100%'
                    }}
                >
                    <Typography>PrivateCourse Times</Typography>
                    {
                        timeSelection.map((item, index) => {
                            return (
                                <Box
                                    key={'time-selection-' + index}
                                    sx={{
                                        width: '100%'
                                    }}
                                >
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <InputLabel id="demo-select-small-label">{item.date_str}</InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={selectedTimes.find((element) => element.date === item.date).time_id}
                                            label={item.date}
                                            variant={"outlined"}
                                            onChange={(e) => handleTimeSelect({time_id: e.target.value, date: item.date})}
                                        >
                                            <MenuItem value="">
                                                <em>-</em>
                                            </MenuItem>
                                            {
                                                item.times.map((time, index) => {
                                                    return (
                                                        <MenuItem
                                                            value={time.time_id}
                                                            key={'time-selection-val-' + time.time_id}
                                                        >
                                                            {time.str}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            )
                        })
                    }
                </Box>
            )
            : <></>
    );
}


function PrivateCourseAdditionalPersonSelection({
                                                    useAdditionalPersons,
                                                    selectedAdditionalPersons,
                                                    setSelectedAdditionalPersons,
                                                    selectedDates,
                                                    fetchAndSetPreCalculatedPrice
                                                }) {
    // --- Person Context for select and add
    const { addPerson, removePerson, persons, activePerson, getFormData, personFormData } = usePersonContext();
    const [initialFormData] = useState(null);
    const [addFormData, setAddFormData] = useState(null);
    const [addFormFields, setAddFormFields] = useState(null);
    const numValues = ['height'];

    // --- open / close Dialog for addd new Person
    const [openDialog, setOpenDialog] = React.useState(false);

    const {t} = useTranslation();

    function handleClose() {
        setOpenDialog(false);
    }

    function handleOpen() {
        setOpenDialog(true);
    }

    const handleSelectAdditionalPerson = (newSelectedPersons) => {
        // console.log('newSelectedPersons', newSelectedPersons);
        setSelectedAdditionalPersons(newSelectedPersons);
        if (selectedDates !== null) {
            fetchAndSetPreCalculatedPrice();
        }
    }

    const handleAddPersonChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;

        if (numValues.includes(e.target.name)) {
            value = e.target.valueAsNumber;
        }
        setAddFormData({
            ...addFormData,
            [name]: value
        });
    }

    const handleBirthAddPersonChange = (newValue) => {
        let formattedValue = null;
        try {
            formattedValue = newValue.format("YYYY-MM-DD");
        } catch (e) {
            return;
        }
        handleAddPersonChange({
            target: {
                name: "birthdate",
                value: formattedValue,
            }
        });
    }

    const handleAddPerson = async () => {
        // get form sb-simple-assistant-add-person-form
        const reject_values = ['', 'Invalid Date', undefined, null, NaN];
        let all_correct = []
        Object.keys(addFormData).forEach((key) => {
            if (!addFormData[key] && reject_values.includes(addFormData[key])) {
                all_correct.push(false);
            }
        });

        if (all_correct.length > 0) {
            alert(t("simple-assistant.errors.notAllFieldsFilled"));
            return;
        }

        let lastAdded = await addPerson(addFormData, false);
        const d = getFormData()
        setAddFormData(d['provided_data_set']);
        let newSelected = selectedAdditionalPersons;
        if (lastAdded !== null && lastAdded !== undefined) {
            newSelected.push(lastAdded);
            handleSelectAdditionalPerson(newSelected)
        }
        setOpenDialog(false);
    }

    React.useEffect(() => {
        const d = getFormData()
        setAddFormData(d['provided_data_set']);
        setAddFormFields(d['fields']);
    }, [openDialog, personFormData]);

    if(useAdditionalPersons !== null && useAdditionalPersons) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    minWidth: '100%',
                    width: '100%'
                }}
            >
                <Box
                    sx={{
                        width: '100%'
                    }}
                >
                    <FormControl sx={{ m: 1, width: '100%' }}>
                        <InputLabel id="muliple-additional-person-select-label-1">Additional Persons</InputLabel>
                        <Select
                            id="muliple-additional-person-select"
                            multiple
                            labelId={"muliple-additional-person-select-label-1"}
                            value={selectedAdditionalPersons}
                            onChange={(e) => handleSelectAdditionalPerson(e.target.value)}
                            input={<OutlinedInput id="muliple-additional-person-select-label-2" label="Additional Persons" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {
                                        selectedAdditionalPersons.map((personId) => {
                                            let person = persons.find((p) => p.id === personId);
                                            let label = 'not found'
                                            if (person !== null && person !== undefined) {
                                                label = person.fname + ' ' + person.sname;
                                            }
                                            return (
                                                <Chip key={'selected-p-' + personId} label={label}/>
                                            )
                                        })
                                    }
                                </Box>
                            )}
                            // MenuProps={MenuProps}
                        >
                            {
                                persons.map((person) => {
                                    if (person.id === activePerson) {
                                        return null;
                                    } else {
                                        return (
                                            <MenuItem
                                                key={'person-' + person.id}
                                                value={person.id}
                                            >
                                                {person.fname} {person.sname}
                                            </MenuItem>
                                        )
                                    }
                                }
                            )}
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        p: 2
                    }}
                >
                    <Button
                        onClick={handleOpen}
                        variant="text"
                    >
                        <AddCircleOutline />
                    </Button>
                </Box>
                <AddNewPerson
                    open={openDialog}
                    handleClose={handleClose}
                    addFormData={addFormData}
                    handleAddPersonChange={handleAddPersonChange}
                    handleBirthAddPersonChange={handleBirthAddPersonChange}
                    handleAddPerson={handleAddPerson}
                    addFormFields={addFormFields}
                />
            </Box>
        )
    } else {
        return null;
    }
}


export {
    PrivateCourseAdditionalPersonSelection,
    PrivateCourseTimeSelection
};