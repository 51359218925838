import './App.css';
import {BrowserRouter as Router} from "react-router-dom";
import PageHandler from "./common/router/PageHandler";
import Header from "./components/controller/header/Header";
import Footer from "./components/controller/footer/Footer";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import MainPage from "./components/placeholder/mainPage";
import {LoadMenuFile, LoadPageStyles} from "./components/templates/TemplateLoader";
import {ErrorProvider} from "./common/errors/ErrorProvide";
import ErrorComponent from "./common/errors/ErrorComponent";
import {CartProvider} from "./common/providers/CartContext";

let pageStyles = {
    header: {
        height: "100px",
        maxWidth: "100%",
    },
    main: {
        maxWidth: "100%",
        marginTop: "20px",
        padding: "0 20px",
    }
}

function App() {

    const { t, ready } = useTranslation();

    const [loading, setLoading] = React.useState(true);
    const [pageConfigurations, setPageConfigurations] = React.useState({});
    const [menuItems, setMenuItems] = React.useState([]);

    const getPageConfigurations = async () => {
        setLoading(true);
        const pageStyles = await LoadPageStyles();
        const menuItems = await LoadMenuFile();
        if (pageStyles !== null && menuItems !== null) {
            setPageConfigurations({
                pageStyles: pageStyles,
                menuItems: menuItems,
            });

            setLoading(false);
        }
    }

    useEffect(() => {
        getPageConfigurations()
    }, []);

    // console.log(t('header.my-profile')); // Should log "Mein Profil" if everything is working

    if (loading || !ready) {
        return (
            <MainPage />
        );
    }

    return (
        <ErrorProvider>
            <div>
                <Router>
                    <CartProvider>
                        <Header pageStyles={pageConfigurations.pageStyles} menuItems={pageConfigurations.menuItems}/>
                        <ErrorComponent />
                        <div id="content" style={{ ...pageConfigurations.pageStyles.main}}>
                            <PageHandler menuItems={pageConfigurations.menuItems}/>
                        </div>
                        <Footer languageSelection={pageConfigurations.pageStyles.languages} />
                    </CartProvider>
                </Router>
            </div>
        </ErrorProvider>
    );
}

export default App;
